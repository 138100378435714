/* You can add global styles to this file, and also import other style files */
@import "./_constants.scss";
@import './material-overrides.scss';

// bootstrap
@import "../../node_modules/bootstrap/scss/bootstrap";
@import "@progress/kendo-theme-material/scss/progressbar/_index.scss";


// Import the entire theme
@import "@progress/kendo-theme-default/dist/all.scss";

html,
body {
  height: 100%;
  width: 100%;
  margin: 0px;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: #fafafa;
}

.clickable:hover {
  cursor: pointer;
}
