@use '@angular/material' as mat;

//define color palettes
/* For use in src/lib/core/theming/_palette.scss */
$md-greenboard-darkblue: (
    50 : #e4e6eb,
    100 : #bbc0cd,
    200 : #8e96ac,
    300 : #606c8b,
    400 : #3e4d72,
    500 : #1c2d59,
    600 : #192851,
    700 : #142248,
    800 : #111c3e,
    900 : #09112e,
    A100 : #697fff,
    A200 : #3653ff,
    A400 : #0327ff,
    A700 : #0021e9,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$md-greenboard-teal: (
    50 : #e0f0f2,
    100 : #b3dbdd,
    200 : #80c3c7,
    300 : #4daab1,
    400 : #2698a0,
    500 : #00868f,
    600 : #007e87,
    700 : #00737c,
    800 : #006972,
    900 : #005660,
    A100 : #90f1ff,
    A200 : #5debff,
    A400 : #2ae4ff,
    A700 : #11e1ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-greenboard-lightblue: (
    50 : #f2faf9,
    100 : #dff2f1,
    200 : #caeae8,
    300 : #b4e2de,
    400 : #a4dbd7,
    500 : #94d5d0,
    600 : #8cd0cb,
    700 : #81cac4,
    800 : #77c4be,
    900 : #65bab3,
    A100 : #ffffff,
    A200 : #f8fffe,
    A400 : #c5fffa,
    A700 : #acfff8,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-greenboard-orange: (
    50 : #fdebe5,
    100 : #fbcdbf,
    200 : #f8ac95,
    300 : #f58b6a,
    400 : #f3724a,
    500 : #f1592a,
    600 : #ef5125,
    700 : #ed481f,
    800 : #eb3e19,
    900 : #e72e0f,
    A100 : #ffffff,
    A200 : #ffe3e0,
    A400 : #ffb6ad,
    A700 : #ff9f93,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);


//define new color palettes
$my-primary: mat.define-palette($md-greenboard-darkblue, 500);
$my-accent: mat.define-palette($md-greenboard-teal, 500);
$my-warn: mat.define-palette($md-greenboard-orange, 500);

//set color palettes
$my-theme: mat.define-light-theme((
 color: (
   primary: $my-primary,
   accent: $my-accent,
   warn: $my-warn,
 )
));

@include mat.all-component-themes($my-theme);
